import { useState } from 'react'
import MaskedInput from 'react-text-mask'
import { api_prod } from '../../services/api'
export const FormMainVenture = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [checkbox, setCheckbox] = useState(false)
  const [formSend, setFormSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    setFormSend(false)
    setAlertMsg('')
    // Verificações de validação
    if (!nome || !email || !telefone || !checkbox) {
      setAlertMsg(
        !checkbox
          ? 'Por favor, aceite a Política de privacidade.'
          : 'Por favor, preencha todos os campos.'
      )
      setLoading(false)
      return
    }
    // Requisição à API
    api_prod
      .get(
        `/empreendimentos/add-subscriber?venture_id=5&phone=${telefone}&email=${email}&name=${nome}&checkbox=true`,
        {
          venture_id: 4,
          phone: telefone,
          email: email,
          name: nome,
          checkbox: true,
        }
      )
      .then(function (response) {
        console.log(response.data)
        setFormSend(true)
        setLoading(false)
        setAlertMsg('')
        // window.location.assign(response.data.results.url)
      })
      .catch(function (error) {
        console.error(error)
        setAlertMsg(
          error.errors[0] || 'Ocorreu um erro ao enviar o formulário.'
        )
        setLoading(false)
      })
    // Limpar os campos após o envio
    setNome('')
    setEmail('')
    setTelefone('')
    setCheckbox(false)
  }
  return (
    <form className="section-formulario-form" id="form" onSubmit={handleSubmit}>
      <div className="header">
        <div>
          <h3>QUER MAIS NOVIDADES</h3>
          <p>Cadastre seu e-mail e receba informações exclusivas.</p>
        </div>
      </div>
      <div className="content-inputs">
        <label htmlFor="">Nome</label>
        <input
          type="text"
          placeholder="Informe seu nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <label htmlFor="">E-mail</label>
        <input
          type="email"
          placeholder="Informe seu e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="">Telefone</label>
        <MaskedInput
          className="input-theme"
          placeholder="(91) 99999-9999"
          mask={[
            '(',
            /[1-9]/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={telefone}
          onChange={(e) => setTelefone(e.target.value)}
        />
        <div id="terms">
          <span htmlFor="">
            <input
              type="checkbox"
              checked={checkbox}
              onChange={(e) => setCheckbox(e.target.checked)}
            />
            <span>
              Aceito e concordo com a{' '}
              <span className="blue">Política de privacidade.</span>
            </span>
          </span>
        </div>
        {loading ? (
          <button type="submit">Enviando sua mensagem</button>
        ) : (
          <button type="submit">QUERO MAIS INFORMAÇÕES</button>
        )}
        {formSend && (
          <p className="text-dark mt-4">
            Mensagem enviada, retornaremos em breve.
          </p>
        )}
        {alertMsg && <p className="text-red mt-4">{alertMsg}</p>}
      </div>
    </form>
  )
}

